<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card>
                    <v-card>
                        <v-card-title class="title">
                            <v-icon>payment</v-icon> &nbsp;EOD Collection Report
                            <v-spacer></v-spacer>
                            <print-buttons :passAuthToReport="true" v-if="downloadUrl && form.items.data.length"
                                           :download-url="downloadUrl"
                                           :pdf="false"></print-buttons>
                        </v-card-title>

                        <v-card-title class="title">
                            <v-flex xs4 sm4>
                                <v-select :items="months" class="pa-0"
                                          label="Months" v-model="month"/>
                            </v-flex>
                            <v-flex xs4 sm4>
                                <search-button :disabled="!month" permission="receipt-read"
                                               @action="get()">
                                    Search
                                </search-button>
                            </v-flex>
                        </v-card-title>
                    </v-card>
                    <v-data-table :headers="headers"
                                  :items="eodData"
                                  hide-default-footer
                                  :options.sync="pagination"
                                  :server-items-length="form.items.meta.total"
                                  footer-props.items-per-page-options="rowsPerPageItems"
                                  :loading="form.loading">
                        <template  v-slot:item="{index, item}">
                            <tr>
                                <td class="text-xs-left" :rowspan="item.fee_head_wise_amount.length + 1">
                                    {{index + 1}}
                                </td>
                                <td class="text-xs-left" :rowspan="item.fee_head_wise_amount.length +1">
                                    {{item.transaction_date}} {{item.fee_head_wise_amount.length}}
                                </td>
                            </tr>
                            <template v-for="(fHamount,i) in item.fee_head_wise_amount">
                                <tr :key="(i+100)*(index+1)">
                                    <td class="text-xs-left">{{fHamount.fee_head}}</td>
                                    <td class="text-xs-left">{{fHamount.amount}}</td>
                                </tr>
                            </template>

                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex'
    import Form from '@/library/Form'

    export default {
        data: () => ({
            form: new Form({
                amount: '',
                enroll_code: '',
                stat: 0,

            }, '/api/report/billing/sod-report'),
            pagination: {
                rowsPerPage: 1000,
            },
            rowsPerPageItems: [25, 50, 75, 100],
            months: [
                {text: 'Baisakh', value: '01'},
                {text: 'Jestha', value: '02'},
                {text: 'Asar', value: '03'},
                {text: 'Shrawan', value: '04'},
                {text: 'Bhadra', value: '05'},
                {text: 'Asoj', value: '06'},
                {text: 'Kartik', value: '07'},
                {text: 'Mangsir', value: '08'},
                {text: 'Poush', value: '09'},
                {text: 'Magh', value: '10'},
                {text: 'Falgun', value: '11'},
                {text: 'Chaitra', value: '12'},
            ],
            headers: [
                {text: '#', align: 'left', value: 'id', sortable: false},
                {text: 'Sod Date', align: 'left', value: 'id', sortable: false},
                {text: 'Fee Head', align: 'left', value: 'fee_head', sortable: false},
                {text: 'Amount', align: 'left', value: 'amount', sortable: false},
            ],
            month: '',
            downloadUrl: '',
            totalSum: 0,
            eodData: [
                {
                    transaction_date: '2075-12-12',
                    fee_head_wise_amount: [
                        {fee_head: 'Monthly Fee', amount: 2000},
                        {fee_head: 'Tution Fee', amount: 3000},
                        {fee_head: 'Admission Fee', amount: 8000}
                    ]
                },
                {
                    transaction_date: '2075-12-13',
                    fee_head_wise_amount: [
                        {fee_head: 'Monthly Fee', amount: 2000},
                        {fee_head: 'Tution Fee', amount: 3000},
                        {fee_head: 'Admission Fee', amount: 8000}
                    ]
                },
            ]

        }),

        computed: {
            ...mapState(['batch']),
        },

        mounted() {
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&') + '&month=' + this.month;
            },

            get(params) {
                if (this.month) {
                    let query = [null, undefined].includes(params) ? this.queryString() : params;
                    this.form.get(null, query).then(({data}) => {
                        this.totalSum = 0;
                        data.data.map(res => {


                            if (!['', null, undefined].includes(res.amount)) {
                                this.totalSum += res.amount;
                            }
                        });
                        this.downloadUrl = data.download_url;
                    })
                }
            },
        }
    }
</script>
<style lang="scss">
    .verified {
        background: #4caf50 !important;
        color: white !important;
        padding: 5px 10px 5px 5px !important;
        border-radius: 5px !important;
        margin-left: 10px !important;
        margin-right: 10px !important;
    }

    .total {
        background: red !important;
        color: white !important;
        padding: 5px 10px 5px 5px !important;
        border-radius: 5px !important;
    }

    .unverified {
        background: #fb8c00 !important;
        color: white !important;
        padding: 5px 10px 5px 5px !important;
        border-radius: 5px !important;
    }

    .inputType {
        border: 1px solid #bbb;
        text-align: center;
        height: 34px;
        width: 86px;

        &:focus {
            outline: none;
        }

        &[disabled] {
            color: #999;
        }
    }

    .inputType:disabled {
        cursor: not-allowed;
        background: #eee;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }
</style>